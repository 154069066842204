<template>
  <div>
    <!-- basic modal -->
    <b-form-group
      :label="$t('İçerik Başlığı')"
      label-for="title"
    >

      <b-form-input v-model="localBlockData.contentTitle" />
    </b-form-group>
    <b-form-group
      :label="$t('Url')"
      label-for="title"
    >

      <b-form-input v-model="localBlockData.contentUrl" />
    </b-form-group>
    <b-form-group
      :label="$t('İçerik')"
      label-for="description"
    >

      <VueEditor
        v-model="localBlockData.content"
        :editor-options="editorSettings"
        use-custom-image-handler
        @image-added="handleImageAdded"
      />
    </b-form-group>
    <hr>

  </div>
</template>

<script>
import {

  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { VueEditor, Quill } from 'vue2-editor'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'
import Compressor from 'compressorjs'
import axios from '@axios'

Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)
export default {
  components: {
    BFormGroup,
    BFormInput,
    VueEditor,
  },
  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: true,

        },
      },
      localBlockData: this.blockData,
    }
  },
  created() {
    // eslint-disable-next-line no-unused-vars
    VueEditor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      const plaintext = node.innerText
      const Delta = Quill.import('delta')
      return new Delta().insert(plaintext)
    })
  },
  methods: {
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      // eslint-disable-next-line no-new

      const { showErrorMessage } = this
      // eslint-disable-next-line no-new
      new Compressor(file, {
        quality: 0.6,

        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
          const formData = new FormData()
          const fileData = new File([result], file.name, { type: result.type })

          formData.append('file', fileData)
          axios
            .post('/api/shop-page-upload-editor-image', formData)
            .then(response => {
              Editor.insertEmbed(cursorLocation, 'image', response.data.data)
              resetUploader()
            })
            .catch(() => {
              showErrorMessage()
            })
            .finally(() => {

            })
        },
        error(err) {
          alert(err)
        },
      })
    },
  },
}
</script>

<style>
.list-group-item {
  transition: all 1s
}
</style>
