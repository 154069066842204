<template>
  <div>
    <!-- basic modal -->
    <b-modal
      id="image-uploader-modal"
      ref="image-uploader-modal"
      title="Yeni Görsel Yükle"
      size="lg"
      hide-footer
    >
      <ImageUploader
        :ratio-selector="true"
        @dataChanged="imageUploaded"
      />
    </b-modal>

    <b-button

      block
      variant="outline-primary"
      @click="$refs['image-uploader-modal'].show();"
    >
      Görsel Yükle
    </b-button>
    <hr>
    <!-- draggable -->
    <draggable
      v-model="localBlockData.items"
      class="list-group list-group-flush cursor-move"
      tag="ul"
    >

      <b-list-group-item
        v-for="(listItem,index) in localBlockData.items"
        :key="index"
        tag="li"
      >
        <div class="d-flex">
          <b-img
            style="max-height:100px;"
            :src="listItem.image_url"

            :alt="listItem.image_name"
          />
          <div class="ml-25">
            <b-card-text class="font-weight-bold mb-0">
              {{ listItem.image_name }}
              <small v-if="listItem.url">Link: {{ listItem.url }}</small>
            </b-card-text>

          </div>
        </div>
      </b-list-group-item>

    </draggable>

  </div>
</template>

<script>
import {
  BListGroupItem, BButton, BModal, BImg, BCardText,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import ImageUploader from './ImageUploader.vue'

export default {
  components: {
    draggable,
    BCardText,
    BImg,
    BListGroupItem,
    BModal,
    BButton,
    ImageUploader,
  },
  props: {
    blockData: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {
      localBlockData: this.blockData,
    }
  },
  methods: {
    imageUploaded(imageData) {
      this.localBlockData.items.push(imageData)
      // console.log(imageData)
      this.$refs['image-uploader-modal'].hide()
    },
  },
}
</script>

<style>
.list-group-item {
  transition: all 1s
}
</style>
