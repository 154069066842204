<template>
  <div>
    <!-- modal -->
    <image-cropper
      v-if="selectedImage"
      :image="selectedImage"
      :ratio-selector="false"
      @cropped="imageCropped"
    />
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(saveData)"
        @reset.prevent="resetForm"
      >

        <validation-provider
          #default="validationContext"
          :name="$t('Görsel Adı')"
          rules="required|min:2"
        >
          <b-form-group
            :label="$t('Görsel Adı')"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="contentData.name"
              autofocus
              autocomplete="off"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-form-group
          :label="$t('Tıklandığında gideceği link')"
          label-for="link-url"
        >
          <b-form-input
            id="link-url"
            v-model="contentData.url"
            autofocus
            autocomplete="off"
          />

        </b-form-group>
        <b-img

          :src="contentData.bg_image_url"
          fluid
        />
        <hr>
        <b-form-group
          :label="$t('Görsel')"
          label-for="image_file_path"
        >
          <b-form-file
            id="image_file_path"
            accept=".jpg, .jpeg"
            :placeholder="$t('Bir dosya seçiniz veya buraya sürükleyiniz')"
            :drop-placeholder="$t('Dosyayı buraya bırakınız...')"
            @change="imageSelected"
          />

        </b-form-group>

        <div class="d-flex align-items-center justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
          >
            {{ $t('Kaydet') }}
          </b-button>
        </div>

      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import {
  BForm, BFormGroup, BButton, VBModal, BFormInput, BFormInvalidFeedback, BImg, BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import imageCropper from '@core/components/image-cropper/ImageCropper.vue'

export default {
  components: {
    BForm,
    BImg,
    BFormGroup,
    BFormFile,
    BFormInput,
    imageCropper,
    BButton,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'modalShow',
    event: 'update:customer-manager-edit-modal-show',
  },

  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      blankContentData: {
        name: '',
        bg_image_url: '',
        url: '',
      },
      componentMode: 'add',
      contentData: null,
      showManagerTitleEditor: false,
      managerTitlesData: [],
      managersData: [],
      selectedIndex: -1,
      createdBySystem: false,
    }
  },
  watch: {
  },
  created() {
    this.resetContentData()
  },
  methods: {
    imageCropped(croppedImage) {
      this.contentData.bg_image_url = croppedImage
      this.croppedImage = croppedImage
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    imageSelected(event) {
      const file = event.target.files ? event.target.files[0] : null

      this.getBase64(file).then(data => { this.selectedImage = data })
    },
    saveData() {
      if (this.croppedImage != null) {
        this.contentData.upload_image = this.croppedImage
      }

      if (this.componentMode === 'add') {
        store.dispatch('app-shop-pagebuilder/uploadImage', this.contentData)
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            const imageData = {
              image_url: response.data.data,
              image_name: this.contentData.name,
              url: this.contentData.url,
            }
            this.$emit('dataChanged', imageData)
            this.resetContentData()
          })
      }
    },
    resetModal() {},
    resetContentData() {
      this.contentData = JSON.parse(JSON.stringify(this.blankContentData))
      this.selectedIndex = -1
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const croppedImage = ref(null)
    const selectedImage = ref(null)

    const onSubmit = () => {
    }
    const resetContentData = () => {
      this.contentData.value = JSON.parse(JSON.stringify(this.blankContentData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetContentData)

    return {
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      croppedImage,
      selectedImage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
