<template>

  <div>
    <!-- basic modal -->
    <b-modal
      id="image-editor-modal"
      ref="image-editor-modal"
      title="Blok İçeriği"
      size="lg"
      hide-footer
    >
      <image-content-editor
        v-if="selectedBlock!=null"
        :block-data="selectedBlock"
      />
    </b-modal>
    <!-- basic modal -->
    <b-modal
      id="product-group-modal"
      ref="product-group-modal"
      title="Ürün Grubu Seçimi"
      size="lg"
      hide-footer
    >
      <product-group-editor
        v-if="selectedBlock!=null"
        :block-data="selectedBlock"
      />
    </b-modal>
    <!-- basic modal -->
    <b-modal
      id="content-editor-modal"
      ref="content-editor-modal"
      title="İçerik Düzenle"
      size="xl"
      hide-footer
    >
      <HtmlContentEditor
        v-if="selectedBlock!=null"
        :block-data="selectedBlock"
      />
    </b-modal>
    <!-- basic modal -->
    <b-modal
      id="special-product-modal"
      ref="special-product-modal"
      title="Özel Ürün Düzenle"
      size="md"
      hide-footer
    >
      <SpecialProductEditor
        v-if="selectedBlock!=null"
        :block-data="selectedBlock"
      />
    </b-modal>
    <!-- basic modal -->
    <b-modal
      v-if="pageData"
      id="add-page-modal"
      ref="add-page-modal"
      title="Sayfa Ekle"
      size="md"
      hide-footer
    >
      <b-form-group
        :label="$t('Sayfa Adı')"
        label-for="page-name"
      >

        <b-form-input v-model="pageData.title" />
      </b-form-group>
      <b-form-group
        :label="$t('Slug')"
        label-for="page-slug"
      >

        <b-form-input v-model="pageData.slug" />
      </b-form-group>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          type="button"
          @click="saveData"
        >
          {{ $t('Kaydet') }}
        </b-button>
      </div>
    </b-modal>
    <b-row>
      <b-col md="12">
        <b-card>

          <b-row>
            <b-col md="12">
              <div class="d-flex align-items-center justify-content-end  mb-2 mb-md-0 ">
                <b-button

                  variant="danger"
                  size="sm"
                  @click="deleteData()"
                >

                  <span class="text-nowrap">{{ $t("Bu sayfayı Sil") }}</span>
                </b-button>
                <b-button
                  id="import-modal-toggle-btn"
                  class="ml-2"
                  variant="primary"
                  size="sm"
                  @click="openAddModal()"
                >

                  <span class="text-nowrap">{{ $t("Yeni Sayfa Ekle") }}</span>
                </b-button>
              </div>
              <b-form-group
                class="mt-2"
                :label="$t('Seçilen Sayfa')"
                label-for="brand"
              >

                <b-form-select
                  v-model="selectedPageId"
                  :options="pages"
                />

              </b-form-group>

            </b-col>
          </b-row>

        </b-card>
      </b-col>
      <b-col md="12">
        <b-card
          v-if="pageData"

          class="mb-0"
        >
          <b-card-title>
            Sayfa İçeriğini Düzenle
          </b-card-title>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Sayfa Adı')"
                label-for="page-name"
              >

                <b-form-input v-model="pageData.title" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('Slug')"
                label-for="slug"
              >

                <b-form-input
                  v-model="pageData.slug"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                :label="$t('Link url')"
                label-for="link-url"
              >

                <b-form-input
                  v-model="pageData.url"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-checkbox
                v-model="pageData.is_active"
                name="check-button"
                switch
                inline
              >
                Sayfa Aktif
              </b-form-checkbox>
              <hr>
              <vue-grid-designer
                v-model="pageData.content"
                :on-new-row="getNewContentRow"
                :on-new-block="getNewContentBlock"
                :mode="editMode?'edit':'view'"
                @block-content-edited="editContent('content-edited', $event)"
                @add-block="showDemoEvent('add-block', $event)"
              />
            </b-col>
          </b-row>
          <hr>
          <div>
            <b-button
              variant="primary"
              @click="saveData"
            >
              Kaydet
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import {
  BCard, BRow, BCol, VBModal, BFormGroup, BCardTitle, BFormSelect, BButton, BFormCheckbox, BModal, BFormInput,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import VueGridDesigner from '@core/components/grid-builder/component'
import pageBuilderStoreModule from './pageBuilderStoreModule'
import ImageContentEditor from './ContentSelector/ImageContentEditor.vue'
import ProductGroupEditor from './ContentSelector/ProductGroupEditor.vue'
import HtmlContentEditor from './ContentSelector/HtmlContentEditor.vue'
import SpecialProductEditor from './ContentSelector/SpecialProductEditor.vue'

export default {
  components: {
    BCard,
    BRow,
    BFormCheckbox,
    SpecialProductEditor,
    ImageContentEditor,
    BFormGroup,
    BFormInput,
    ProductGroupEditor,
    BButton,
    BModal,
    VueGridDesigner,
    BCol,
    HtmlContentEditor,
    BFormSelect,
    // Form Validation
    BCardTitle,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      grid: [],
      showMarkAsReturnedModal: false,
      returnCompletionDate: '',
      showEditSidebar: false,
      menuTitle: '',
      menuGroupTitle: '',
      menuUrl: '',
      editMode: true,
      pageData: null,
      selectedPageId: 0,
      blankPageData: {
        name: '',
        title: '',
        content: '[]',
        slug: '',
        url: '',
        is_active: 0,
        id: 0,
      },

      selectedBlock: null,
      pages: [],
    }
  },
  watch: {
    selectedPageId(val) {
      this.fetchSinglePage(val)
    },
    'pageData.title': function (val) {
      this.pageData.slug = this.makeSlug(val)
    },
  },
  created() {
    this.fetchCategories()
    this.fetchBrands()
    this.fetchPageData()
  //  this.resetPageData()
  },
  methods: {
    makeSlug(text) {
      let textString = text
      textString = textString.replace(/ /g, '-')
      textString = textString.replace(/</g, '')
      textString = textString.replace(/>/g, '')
      textString = textString.replace(/"/g, '')
      textString = textString.replace(/é/g, '')
      textString = textString.replace(/!/g, '')
      textString = textString.replace(/’/, '')
      textString = textString.replace(/£/, '')
      textString = textString.replace(/^/, '')
      textString = textString.replace(/#/, '')
      textString = textString.replace(/$/, '')
      textString = textString.replace(/\+/g, '')
      textString = textString.replace(/%/g, '')
      textString = textString.replace(/½/g, '')
      textString = textString.replace(/&/g, '')
      textString = textString.replace(/\//g, '')
      textString = textString.replace(/{/g, '')
      textString = textString.replace(/\(/g, '')
      textString = textString.replace(/\[/g, '')
      textString = textString.replace(/\)/g, '')
      textString = textString.replace(/]/g, '')
      textString = textString.replace(/=/g, '')
      textString = textString.replace(/}/g, '')
      textString = textString.replace(/\?/g, '')
      textString = textString.replace(/\*/g, '')
      textString = textString.replace(/@/g, '')
      textString = textString.replace(/€/g, '')
      textString = textString.replace(/~/g, '')
      textString = textString.replace(/æ/g, '')
      textString = textString.replace(/ß/g, '')
      textString = textString.replace(/;/g, '')
      textString = textString.replace(/,/g, '')
      textString = textString.replace(/`/g, '')
      textString = textString.replace(/|/g, '')
      textString = textString.replace(/\./g, '')
      textString = textString.replace(/:/g, '')
      textString = textString.replace(/İ/g, 'i')
      textString = textString.replace(/I/g, 'i')
      textString = textString.replace(/ı/g, 'i')
      textString = textString.replace(/ğ/g, 'g')
      textString = textString.replace(/Ğ/g, 'g')
      textString = textString.replace(/ü/g, 'u')
      textString = textString.replace(/Ü/g, 'u')
      textString = textString.replace(/ş/g, 's')
      textString = textString.replace(/Ş/g, 's')
      textString = textString.replace(/ö/g, 'o')
      textString = textString.replace(/Ö/g, 'o')
      textString = textString.replace(/ç/g, 'c')
      textString = textString.replace(/Ç/g, 'c')
      textString = textString.replace(/–/g, '-')
      textString = textString.replace(/—/g, '-')
      textString = textString.replace(/—-/g, '-')
      textString = textString.replace(/—-/g, '-')

      return textString.toLowerCase()
    },
    openAddModal() {
      this.resetPageData()
      this.$refs['add-page-modal'].show()
    },
    resetPageData() {
      this.pageData = JSON.parse(JSON.stringify(this.blankPageData))
    },
    editContent(type, event) {
      this.selectedBlock = event.vdg.block
      if (this.selectedBlock.contentType === 1 || this.selectedBlock.contentType === 2) {
        this.$refs['image-editor-modal'].show()
      } else if (this.selectedBlock.contentType === 3) {
        this.$refs['product-group-modal'].show()
      } else if (this.selectedBlock.contentType === 5) {
        this.$refs['content-editor-modal'].show()
      } else if (this.selectedBlock.contentType === 4) {
        this.$refs['special-product-modal'].show()
      }
    },
    // eslint-disable-next-line no-unused-vars
    showDemoEvent(type, event) {
      // console.log(event)
    },
    async getNewContentRow() {
      return {
        label: 'label1',
        blocks: [
          await this.getNewContentBlock(),
        ],
      }
    },

    async getNewContentBlock(row, span, contentType) {
      const contentData = {
        span: span || 4, contentType, content: '', source: '',
      }
      if (contentType === 1 || contentType === 2) { // content type 1 ise slider 2 ise banner
        contentData.items = []
        contentData.contentUrl = ''
      }
      if (contentType === 3) { // content type 3 ise ürün grubu
        contentData.brands = []
        contentData.title = ''
        contentData.categories = []
        contentData.limit = 6
        contentData.ordering = 'created_at'
        contentData.order_type = 'asc'
      }
      if (contentType === 4) { // content type 4 ise seçilen ürünler
        contentData.items = []
        contentData.title = ''
      }
      if (contentType === 5) { // content type 5 ise html içeriği
        contentData.contentTitle = ''
        contentData.content = ''
      }
      return contentData
    },
    fetchCategories() {
      store.dispatch('app-shop-pagebuilder/fetchCategories', { list_type: 'all' })
        .then(response => {
          const arr = []
          response.data.data.forEach(val => arr.push({ text: val.name_with_parents, value: val.id }))
          this.$dynamicParams.productCategoriesData = arr
          // console.log(this.$dynamicParams)
        })
    },
    fetchBrands() {
      store.dispatch('app-shop-pagebuilder/fetchBrands', { list_type: 'all' })
        .then(response => {
          const arr = []
          response.data.data.forEach(val => arr.push({ text: val.name, value: val.id }))
          this.$dynamicParams.productBrandsData = arr
        })
    },
    fetchPageData() {
      store.dispatch('app-shop-pagebuilder/fetchPages')
        .then(response => {
          const arr = []
          response.data.data.forEach(val => arr.push({ text: val.title, value: val.id }))
          this.pages = arr
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    fetchSinglePage(id) {
      store.dispatch('app-shop-pagebuilder/fetchSinglePage', { id })
        .then(response => {
          this.pageData = response.data.data
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    // eslint-disable-next-line no-unused-vars
    saveData() {
      if (this.pageData.id === 0) {
        store.dispatch('app-shop-pagebuilder/addPage', this.pageData)
          .then(response => {
            this.selectedPageId = response.data.data.id
            this.fetchPageData()

            this.showSuccessMessage()
          })
          .catch(() => {
            this.showErrorMessage()
          })
      } else {
        store.dispatch('app-shop-pagebuilder/updatePage', this.pageData)
          .then(() => {
            this.fetchPageData()

            this.showSuccessMessage()
          })
          .catch(() => {
            this.showErrorMessage()
          })
      }
    },
    // eslint-disable-next-line no-unused-vars
    deleteData() {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Önemli bir sayfayı siliyor olabilirsiniz. Bu sayfayı silmek istediğinizden emin misiniz ?  (Bu işlem geri alınamaz.)'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-shop-pagebuilder/deletePage', { id: this.pageData.id })
            .then(() => {
              window.location.reload()

              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },

    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-shop-pagebuilder'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, pageBuilderStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // fetchBrands()
    const resetRoleData = () => {
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRoleData)
    return {
      refFormObserver,
      getValidationState,
      resetRoleData,
      resetForm,
      snowOption: {
        theme: 'snow',
      },
    }
  },
}
</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.list-group-item {
  transition: all 1s
}
#add-new-role-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
