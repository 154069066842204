<template>
  <div>
    <!-- basic modal -->
    <b-form-group
      :label="$t('Eklenecek Kategori')"
      label-for="other_categories"
    >
      <b-form-group
        :label="$t('İçerik Başlığı')"
        label-for="title"
      >

        <b-form-input v-model="blockData.title" />
      </b-form-group>
      <v-select
        id="other_categories"
        v-model="blockData.categories"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        multiple
        :options="$dynamicParams.productCategoriesData"
        :reduce="val => val.value"
        label="text"
        :clearable="false"
        input-id="other_categories"
      />

    </b-form-group>
    <b-form-group
      :label="$t('Marka')"
      label-for="brand"
    >
      <v-select
        id="brands"
        v-model="blockData.brands"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        multiple
        :options="$dynamicParams.productBrandsData"
        :reduce="val => val.value"
        label="text"
        :clearable="false"
        input-id="brands"
      />

    </b-form-group>
    <b-form-group
      :label="$t('Listelenecek Ürün Sayısı')"
      label-for="product-count"
    >
      <b-form-input v-model="blockData.limit" />

    </b-form-group>
    <b-form-group
      :label="$t('Ürün Sıralaması')"
      label-for="product-ordering"
    >
      <v-select
        id="product-ordering"
        v-model="blockData.ordering"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

        :options="orderingOptions"
        :reduce="val => val.value"
        label="text"
        :clearable="false"
        input-id="product-ordering"
      />

    </b-form-group>
    <b-form-group
      :label="$t('Ürün Sıralaması Türü')"
      label-for="product-orderby"
    >
      <v-select
        id="product-orderby"
        v-model="blockData.order_type"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

        :options="orderTypes"
        :reduce="val => val.value"
        label="text"
        :clearable="false"
        input-id="product-orderby"
      />

    </b-form-group>
    <hr>

  </div>
</template>

<script>
import {

  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BFormInput,
    BFormGroup,
  },
  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      orderingOptions: [
        { text: 'Ürün Adı', value: 'name' },
        { text: 'Liste Fiyatı', value: 'price' },
        { text: 'Ekleme Tarihi', value: 'created_at' },
      ],
      orderTypes: [
        { text: 'Artan', value: 'asc' },
        { text: 'Azalan', value: 'desc' },
      ],
      localBlockData: this.blockData,
    }
  },
  created() {

  },
  methods: {

  },
}
</script>

<style>
.list-group-item {
  transition: all 1s
}
</style>
