<template>
  <div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      ref="my-modal"
      title="Basic Modal"
      ok-only
      ok-title="Accept"
    >
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        block
        variant="outline-primary"
        @click="addBlock(eventData, rowData,1);"
      >
        Slider Ekle
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        block
        variant="outline-primary"
        @click="addBlock(eventData, rowData,2);"
      >
        Banner Ekle
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        block
        variant="outline-primary"
        @click="addBlock(eventData, rowData,3);"
      >
        Ürün Grubu Ekle
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        block
        variant="outline-primary"
        @click="addBlock(eventData, rowData,4);"
      >
        Seçilmiş Ürün Ekle
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        block
        variant="outline-primary"
        @click="addBlock(eventData, rowData,5);"
      >
        İçerik Ekle
      </b-button>
    </b-modal>
    <div class="vgd">

      <!-- Rows -->
      <div class="vgd__rows">

        <div
          v-for="row in rows"
          :key="row._id"
          :ref="`row_${row._id}`"
          :class="rowClass"
          :data-id="row._id"
          :style="getAnimationStyle()"
          class="vgd__row use-hover"
        >

          <!-- Row toolbar -->
          <div
            v-if="mode === 'edit'"
            :style="getAnimationStyle()"
            class="vgd__row__toolbar no-drag"
          >
            <slot
              :addBlock="addBlock"
              :deleteRow="deleteRow"
              :row="row"
              name="row-toolbar"
            >

              <span
                class="vgd__row__toolbar__button"
                title="Delete row"
                @click="deleteRow($event, row)"
              >
                <font-awesome-icon :icon="['fas', 'times']" />
              </span>

              <span
                class="vgd__row__toolbar__button"
                title="Add block"
                @click="$refs['my-modal'].show(); eventData=$event; rowData=row;"
              >
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
              <!-- context -->

            </slot>

          </div>

          <!-- Blocks -->
          <div
            v-for="block in row.blocks"
            :key="block._id"
            :class="blockClass"
            :data-id="block._id"
            :style="getBlockStyles(row, block)"
            class="vgd__block"
          >
            <h3
              v-if="block.contentType==1"
              style="text-align:center;"
            >
              Slider
            </h3>
            <h3
              v-if="block.contentType==2"
              style="text-align:center;"
            >
              Banner
            </h3>
            <h3
              v-if="block.contentType==3"
              style="text-align:center;"
            >
              Ürün Grubu
            </h3>
            <h3
              v-if="block.contentType==5"
              style="text-align:center;"
            >
              İçerik
            </h3>
            <div v-if="block.contentType==1 || block.contentType==2">
              <b-list-group-item
                v-for="(listItem,index) in block.items"

                :key="index"
                tag="li"
              >
                <div class="d-flex justify-content-between flex-wrap align-items-center">
                  <div>
                    <b-img
                      style="max-height:20px;"
                      :src="listItem.image_url"

                      :alt="listItem.image_name"
                    />
                    <div class="ml-25">
                      <b-card-text class="font-weight-bold mb-0">
                        {{ listItem.image_name }}
                      </b-card-text>

                    </div>
                  </div>
                  <b-button
                    variant="flat-danger"
                    @click="block.items.splice(index,1)"
                  >
                    Sil
                  </b-button>
                </div>
              </b-list-group-item>
            </div>
            <div v-if="block.contentType==3">
              <div v-if="block.categories.length>0">
                <h5>Seçilen Kategoriler</h5>
                <b-list-group-item
                  v-for="(listItem,index) in block.categories"
                  :key="index"

                  variant="dark"
                  tag="li"
                >
                  <div class="d-flex justify-content-between flex-wrap align-items-center">

                    <div class="ml-25">
                      <b-card-text class="font-weight-bold mb-0">
                        {{ $dynamicParams.getCategoryName(listItem) }}
                      </b-card-text>

                    </div>
                    <b-button
                      variant="flat-danger"
                      @click="block.categories.splice(index,1)"
                    >
                      Sil
                    </b-button>
                  </div>
                </b-list-group-item>
              </div>
              <div v-if="block.brands.length>0">
                <h5>Seçilen Markalar</h5>
                <b-list-group-item
                  v-for="(listItem,index) in block.brands"
                  :key="index"

                  variant="dark"
                  tag="li"
                >
                  <div class="d-flex justify-content-between flex-wrap align-items-center">

                    <div class="ml-25">
                      <b-card-text class="font-weight-bold mb-0">
                        {{ $dynamicParams.getBrandName(listItem) }}
                      </b-card-text>

                    </div>
                    <b-button
                      variant="flat-danger"
                      @click="block.brands.splice(index,1)"
                    >
                      Sil
                    </b-button>
                  </div>
                </b-list-group-item>
              </div>

            </div>
            <div v-if="block.contentType==4">
              <b-list-group-item
                v-for="(listItem,index) in block.items"

                :key="index"
                tag="li"
              >
                <div class="d-flex justify-content-between flex-wrap align-items-center">
                  <div>
                    <b-img
                      style="max-height:20px;"
                      :src="listItem.image"
                    />
                    <div class="ml-25">
                      <b-card-text class="font-weight-bold mb-0">
                        {{ listItem.name }}
                      </b-card-text>

                    </div>

                  </div>
                  <b-button
                    variant="flat-danger"
                    @click="block.items.splice(index,1)"
                  >
                    Sil
                  </b-button>
                </div>
              </b-list-group-item>
            </div>
            <div v-if="block.contentType==5">
              <h5>{{ block.contentTitle }}</h5>
            </div>
            <!-- Block toolbar -->
            <div
              v-if="mode === 'edit'"
              :style="getAnimationStyle()"
              class="vgd__block__toolbar no-drag"
            >

              <slot
                :block="block"
                :collapseBlock="collapseBlock"
                :expandBlock="expandBlock"
                :editContent="editContent"
                :row="row"
                name="block-toolbar"
              >
                <span

                  class="vgd__block__toolbar__button"
                  title="Düzenle"
                  @click="editContent($event, row, block)"
                >
                  <font-awesome-icon :icon="['fas', 'edit']" />
                </span>
                <span
                  :class="{ disabled: block.span >= blocksPerRow }"
                  class="vgd__block__toolbar__button"
                  title="Genişlet"
                  @click="expandBlock($event, row, block)"
                >
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </span>

                <span
                  :class="{ disabled: block.span <= 1 }"
                  class="vgd__block__toolbar__button"
                  title="Daralt"
                  @click="collapseBlock($event, row, block)"
                >
                  <font-awesome-icon :icon="['fas', 'minus']" />
                </span>

                <span
                  class="vgd__block__toolbar__button"
                  title="Sil"
                  @click="deleteBlock($event, row, block)"
                >
                  <font-awesome-icon :icon="['fas', 'times']" />
                </span>

              </slot>

            </div>

            <!-- Block content -->
            <div
              v-if="mode === 'view'"
              class="vgd__block__content"
              v-html="block.content"
            />

          </div>

        </div>
      </div>

      <div
        v-show="mode === 'edit'"
        class="no-drag"
      >
        <slot
          :addRow="addRow"
          :maxRows="maxRows"
          name="footer"
        >
          <button
            :disabled="maxRows > 0 && rows.length >= maxRows"
            class="vgd__footer__button"
            @click="addRow($event)"
          >Yeni Satır Ekle
          </button>
        </slot>
      </div>

    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPlus,
  faMinus,
  faTimes,
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  BModal, BButton, BImg, BCardText, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ComponentMixin from './mixins/component.mixin'
import PropsMixin from './mixins/props.mixin'

library.add(faPlus)
library.add(faMinus)
library.add(faTimes)
library.add(faEdit)
export default {

  // Module/component name

  components: {
    FontAwesomeIcon,
    BImg,
    BCardText,
    BListGroupItem,
    BModal,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [
    PropsMixin,
    ComponentMixin,
  ],
  data() {
    return {
      eventData: null,
      rowData: null,
    }
  },

}
</script>

<style lang="scss">
:root {
    --color-highlight: 55, 114, 255;
    --color-highlight-faded: 215, 227, 255;
    --color-active: 150, 5, 5;
    --color-black: 0, 0, 0;
    --color-white: 255, 255, 255;
    --color-lightgrey: 240, 240, 240;
    --color-darkgrey: 76, 76, 76;
}
</style>

<style lang="scss" scoped>
.vgd {

    font-family: sans-serif;

    &__row {

        position: relative;
        min-height: 65px;

        &__toolbar {

            display: flex;
            visibility: hidden;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            top: 0;
            bottom: 0;
            right: -30px;
            width: 30px;
            background-color: rgb(var(--color-highlight-faded));
            padding: 0;

            &__button {

                cursor: pointer;
                width: 100%;
                text-align: center;
                color: rgb(var(--color-darkgrey));
                margin: 4px 0 4px -4px;

                &:hover {
                    color: rgb(var(--color-highlight));
                }

                &.disabled {
                    cursor: default;
                    color: rgb(var(--color-lightgrey));
                    opacity: 0.4;
                }

            }

        }

    }

    &__block {

        position: relative;
        vertical-align: top;
        box-sizing: border-box;
        display: inline-block;
        background-color: rgb(var(--color-lightgrey));
        padding: 0;
        width: calc(var(--block-width) - var(--block-total-margin));

        &__toolbar {

            cursor: initial;
            display: block;
            visibility: hidden;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -15px;
            margin-left: -38px;
            padding: 0;
            height: 30px;
            width: 76px;
            line-height: 30px;
            z-index: 10;
            text-align: center;
            background-color: rgba(var(--color-black), .3);

            &__drag-handle {
                float: left;
                margin-left: 6px;
            }

            &__button {

                cursor: pointer;
                color: lightgray;
                margin-right: 4px;

                &:hover {
                    color: rgb(var(--color-white));
                }

                &.disabled {
                    cursor: not-allowed;
                    opacity: 0.4;
                }

            }

        }

        &__content {
            color: rgb(var(--color-darkgrey));
            text-align: center;
        }

        &--drag {

            opacity: .7;

        }

        &--chosen {

            opacity: .7;

        }

        &--ghost {

            opacity: .2;
            background-color: rgb(var(--color-active));

        }

    }

    &__footer__button {

        border: 0 none;
        padding: .3rem .6rem;
        background-color: rgb(var(--color-darkgrey));
        color: rgb(var(--color-white));
        margin: 10px 6px;
        cursor: pointer;

        &:hover {
            background-color: rgb(var(--color-highlight));
        }

        &[disabled] {
            cursor: not-allowed;
            opacity: 0.4;
            background-color: rgb(var(--color-darkgrey));
        }

    }

    /* Chrome has a hover bug https://github.com/SortableJS/Sortable/issues/232 */
    .use-hover {

        &.vgd__row:hover {

            background-color: rgb(var(--color-highlight-faded));

            .vgd__row__toolbar {
                visibility: visible;
            }

        }

        .vgd__block:hover {

            background-color: rgb(var(--color-highlight));

            .vgd__block__toolbar {
                visibility: visible;
            }

            &:after {

            }

        }

    }

    &.editmode {
        .use-hover {

            &.vgd__row:hover {
                cursor: move;

                &:after {
                    cursor: move;
                    content: "............";
                    position: absolute;
                    left: -20px;
                    top: 0px;
                    padding-left: 9px;
                    padding-bottom: 5px;
                    width: 20px;
                    height: 100%;
                    vertical-align: middle;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    background-color: rgb(var(--color-highlight-faded));
                    line-break: anywhere;
                    line-height: 5px;
                }
            }
        }
    }

}
</style>
