import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    // Pages --------------------
    addPage(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/shop-pages', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePage(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/shop-pages/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePage(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/shop-pages/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPages(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/shop-pages', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSinglePage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/shop-pages/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImage(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/shop-page-upload-image', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // -------types --------------
    // categories
    fetchCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/productcategories', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // brands
    fetchBrands(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/product-brands', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
